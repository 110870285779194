import React, { FC } from 'react';
import { Link } from 'gatsby';

import { getQueryStringValue, useQueryBool } from '../../state/use-query';

const AstronautDetailBar: FC<{ filterFields: string[] }> = ({
  filterFields
}) => {
  const selectedFilters: { [key: string]: string } = {};
  filterFields.forEach((field) => {
    const value = getQueryStringValue(field);
    if (value) {
      selectedFilters[field] = value;
    }
  });
  const [useList] = useQueryBool('list');

  let backPath = '/astronauts/';
  if (typeof window !== `undefined`) {
    backPath = backPath + window.location.search;
  }

  const breadcrumbFields = Object.keys(selectedFilters);

  return (
    <div className="astronaut_detail_header">
      <div className="astronaut_detail_header__bar">
        <div className="container--xl y mxa f fr aic px075">
          <div
            className={'pr1 clickable'}
            onClick={() => {
              if (typeof window !== `undefined`) {
                window.history.back();
              }
            }}
          >
            <span className="astronaut_detail_header__arrow" />
          </div>

          <div className="show--m x">
            <div className="mx1 f fr f1 aic y">
              {breadcrumbFields.length > 0 && (
                <div className="h5 caps f fr aic">
                  <div className="mr15">Displaying:</div>

                  {breadcrumbFields.map((field, i) => (
                    <div className="f fr aic y" key={`crumb` + i}>
                      {i > 0 && (
                        <span className="mx1 akkura small mt025">/</span>
                      )}
                      <Link
                        to={`/astronauts/?list=${useList || false}&${field}=${
                          selectedFilters[field]
                        }`}
                        className="akkura small caps mt025"
                      >
                        <span>{selectedFilters[field]}</span>
                      </Link>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="hide--m x">
            <div className="mx1 f fr f1 aic y jce">
              <div className="h5 mb0 caps">
                {breadcrumbFields.length > 0 && (
                  <div className="h5 caps f fr aic">
                    {breadcrumbFields.map((field, i) => (
                      <div className="f fr aic y" key={`crumb` + i}>
                        {i > 0 && (
                          <span className="mx1 akkura small mt025">/</span>
                        )}
                        <Link
                          to={`/astronauts/?list=${useList || false}&${field}=${
                            selectedFilters[field]
                          }`}
                          className="akkura small caps mt025"
                        >
                          <span>{selectedFilters[field]}</span>
                        </Link>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AstronautDetailBar;
