import React, { FC } from 'react';

const SubmitFeedback: FC<{ style?: any }> = ({ style = {} }) => (
  <div className={'abs pt15'} style={{ ...style }}>
    <div className="rel f fc aic">
      <div
        className={'mouseover__chevron abs'}
        style={{ backgroundColor: '#f4f4f4' }}
      />
      <div
        className={'mouseover__contents rel py05 px075 bau caps small ac'}
        style={{
          color: 'black',
          backgroundColor: '#f4f4f4',
          width: 'max-content',
        }}
      >
        Know something we don’t? <br /> <br />
        <a
          className="under"
          href="mailto:info@supercluster.com?subject=Supercluster IADB Feedback"
        >
          Email us
        </a>
        <br /> <br />
        We’re committed to making
        <br />
        the Astronaut Database
        <br />
        as accurate as possible.
      </div>
    </div>
  </div>
);

export default SubmitFeedback;
