import React, { FC, useState } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import {
  Astronaut,
  deriveAstronautIsAnimal,
  deriveAstronautIsRobot,
  deriveAstronautOccupationInfo,
  formatSimpleDate,
  capitalize
} from 'superclient';
import ImageSanity from '../core/image-sanity';
import MouseOver from '../core/mouse-over';
import MiniTicker from '../core/mini-ticker';
import SubmitFeedback from '../core/submit-feedback';

const AstronautDetailHeader: FC<{ astronaut: Astronaut }> = ({ astronaut }) => {
  const isAnimal = deriveAstronautIsAnimal(astronaut);
  const isRobot = deriveAstronautIsRobot(astronaut);
  const {
    astroPageDisplay,
    occupationMissing,
    isMilitary
  } = deriveAstronautOccupationInfo(astronaut);

  const [mouseOverNation, setMouseOverNation] = useState(-1);
  const [mouseOverAgency, setMouseOverAgency] = useState(-1);
  const [mouseOverDataMissing, setMouseOverDataMissing] = useState(-1);
  const [mouseOverFeedback, setMouseOverFeedback] = useState(-1);

  const dataMissing = (title, i) => (
    <div className="f jcfs">
      <div
        className="rel h4 under f jcc"
        onMouseEnter={() => setMouseOverDataMissing(i)}
        onMouseLeave={() => {
          setMouseOverDataMissing(-1);
        }}
      >
        <span className="pointer">{title}</span>
        <div
          className="abs jcc "
          style={{
            display:
              mouseOverDataMissing === i || mouseOverFeedback === i ? 'flex' : 'none'
          }}
          onMouseEnter={() => setMouseOverFeedback(i)}
          onMouseLeave={() => {
            setMouseOverFeedback(-1);
          }}
        >
          <SubmitFeedback />
        </div>
      </div>
    </div>
  );

  const agenciesNations = (width) => (
    <>
      {astronaut.nations &&
        astronaut.nations.map((nation, i) => {
          return nation.flag ? (
            <Link
              key={nation._id}
              className="rel mb1 f fr jcc"
              to={`/astronauts/?nation=${encodeURIComponent(nation.name)}`}
              style={{
                width: width,
                height: width / nation.flag.asset.metadata.dimensions.aspectRatio
              }}
              onMouseEnter={() => setMouseOverNation(i)}
              onMouseLeave={() => setMouseOverNation(-1)}
            >
              <ImageSanity
                className="abs x y top left"
                image={nation.flag}
                alt={nation.name}
                width={250}
              />
              <MouseOver
                text={nation.name}
                textColor="white"
                backgroundColor="#241F21"
                className={classNames('abs', {
                  hide: mouseOverNation !== i
                })}
                style={{
                  marginTop:
                    width / nation.flag.asset.metadata.dimensions.aspectRatio
                }}
              />
            </Link>
          ) : (
            <div />
          );
        })}
      {astronaut.agencies &&
        astronaut.agencies.map((agency, i) => {
          return agency.icon ? (
            <div
              key={agency._id}
              className="rel mb1 f fr jcc "
              style={{
                width: width,
                height: width / agency.icon.asset.metadata.dimensions.aspectRatio
              }}
              onMouseEnter={() => setMouseOverAgency(i)}
              onMouseLeave={() => setMouseOverAgency(-1)}
            >
              <ImageSanity
                className="abs x y top left"
                image={agency.icon}
                alt={agency.name}
                width={250}
              />
              <MouseOver
                text={agency.name}
                textColor="white"
                backgroundColor="#241F21"
                className={classNames('abs', {
                  hide: mouseOverAgency !== i
                })}
                style={{
                  marginTop:
                    width / agency.icon.asset.metadata.dimensions.aspectRatio
                }}
              />
            </div>
          ) : (
            <div />
          );
        })}
    </>
  );

  const nameCard = (p) => (
    <div
      className={classNames('', {
        bcbl: astronaut.inSpace
      })}
    >
      <div className={classNames(`astronaut_page__header_split rel f fc ${p}`)}>
        <div className="astronaut_page__flags_tablet abs right pr2 pt05 f fc">
          {agenciesNations(40)}
        </div>
        <div className="astronaut_page__flags_desktop abs right pr2 f fc">
          {agenciesNations(70)}
        </div>
        <div className="astronaut_page__name_card">
          <div
            className={classNames('f fr aic x akkura small', {
              cw: astronaut.inSpace,
              cbl: !astronaut.inSpace
            })}
          >
            {astronaut.astroNumber}
            {astronaut.inSpace && (
              <div className="ml2 akkura small caps rel cw">
                <img
                  src="/orbit-white.gif"
                  className="abs top bottom"
                  style={{
                    width: '29px',
                    height: '29px',
                    top: '-6px'
                  }}
                />
                In Space
              </div>
            )}
          </div>

          <div className="f fr">
            <div
              className={classNames('astronaut_page__name_desktop fa mt025 mr05', {
                cw: astronaut.inSpace,
                cb: !astronaut.inSpace
              })}
            >
              {astronaut.name}
              <div className="x h4 mt075 cb">
                {`${
                  astronaut.birthdate
                    ? `b. ${formatSimpleDate(astronaut.birthdate)}`
                    : 'Birthdate Unknown'
                }${
                  astronaut.deathdate
                    ? `   —   d. ${formatSimpleDate(astronaut.deathdate)}`
                    : ''
                }`}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const stats = (px, mobile = false) => (
    <div
      className={classNames(`f fc ${px} py1 f1 bcw`, {
        astronaut_page__header_split: !mobile
      })}
    >
      <div className="f f1 aic fr pb1">
        <div className="f fc f1">
          <div className="mt1 akkura small caps">LIFE FORM</div>
          <div className="h4">
            <Link
              to={`/astronauts/?species=${astronaut.speciesGroup}`}
              className="under"
            >
              {capitalize(astronaut.species)}
            </Link>
          </div>
        </div>
        {!isRobot && (
          <div className="f fc f1">
            <div className="mt1 akkura small caps">
              {isAnimal ? 'Sex' : 'Gender'}
            </div>
            <div className="h4">
              {astronaut.gender ? (
                <Link
                  to={`/astronauts/?gender=${astronaut.gender}`}
                  className="under"
                >
                  {capitalize(astronaut.gender)}
                </Link>
              ) : (
                dataMissing('Data Missing', 1)
              )}
            </div>
          </div>
        )}
        {!isAnimal && (
          <div className="f fc f2">
            <div className="mt1 akkura small caps">
              {isMilitary ? 'Rank' : 'Occupation'}
            </div>
            <div className="h4">
              {astroPageDisplay}
              {occupationMissing && dataMissing('Data Missing', 2)}
            </div>
          </div>
        )}
      </div>

      <div className="astronaut_page__divider bcb" />

      <div className="f fr f1 mt2">
        <div className="f fc f1 jcc">
          <div className="akkura small caps">MISSIONS</div>
          <div className="astronaut_page__big_stats">{astronaut.missionCount}</div>
          <div className="mt1 akkura small caps">TIME IN SPACE</div>
          <div className="">
            {astronaut.totalMinutesInSpace !== undefined && (
              <MiniTicker
                duration={astronaut.totalMinutesInSpace * 60 * 1000}
                ongoingSince={
                  astronaut.lastActiveLaunchDate
                    ? astronaut.lastActiveLaunchDate.utc
                    : undefined
                }
                showSeconds={astronaut.inSpace}
                className="bau s16"
              />
            )}
            {astronaut.totalMinutesInSpace === undefined &&
              dataMissing('Data Missing', 3)}
          </div>
        </div>
        {!isAnimal && (
          <div className="f fc f1 jcc">
            <div className="akkura small caps">SPACEWALKS</div>
            <div className="astronaut_page__big_stats">
              {astronaut.spacewalkCount > 0 ? astronaut.spacewalkCount : '-'}
            </div>
            <div className="mt1 akkura small caps">SPACEWALK TIME</div>

            {astronaut.totalSecondsSpacewalking !== undefined &&
              (astronaut.totalSecondsSpacewalking > 0 ||
                astronaut.lastSpacewalkStartTime !== undefined) && (
                <div className="akkura small caps">
                  <MiniTicker
                    duration={astronaut.totalSecondsSpacewalking * 1000}
                    ongoingSince={
                      astronaut.lastSpacewalkStartTime
                        ? astronaut.lastSpacewalkStartTime.utc
                        : undefined
                    }
                    className="bau s16"
                  />
                </div>
              )}
            {astronaut.totalSecondsSpacewalking === 0 &&
              astronaut.lastSpacewalkStartTime === undefined && (
                <div className="astronaut_page__big_stats">-</div>
              )}
            {astronaut.totalSecondsSpacewalking === undefined &&
              dataMissing('Data Missing', 4)}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <>
      <div className="astronaut_page__header_mobile bcg">
        <div className="astronaut_page__image bcb f1 rel">
          <ImageSanity
            className="abs x y top left"
            image={astronaut.image}
            alt={astronaut.name}
            width={800}
            height={640}
          />
        </div>

        {nameCard('px1 py15')}
        {stats('px1', true)}
      </div>

      <div className="astronaut_page__header_desktop x f fr rel">
        <div className="f f1 fr">
          <div className="astronaut_page__image bcb f1 rel">
            <ImageSanity
              className="abs x y top left"
              image={astronaut.image}
              alt={astronaut.name}
              width={800}
              height={800}
            />
          </div>
          <div className="f1 rel f fc" />
        </div>

        <div className="astronaut_page__stats x f fr">
          <div className="astronaut_page__image f1 rel" />
          <div className="f1 rel f fc">
            {nameCard('px2 py2')}
            {stats('px2')}
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(AstronautDetailHeader);
