import React, { FC } from 'react';
import { Astronaut } from 'superclient';
import ImageSanity from '../core/image-sanity';

const AstronautOtherImages: FC<{ astronaut: Astronaut }> = ({ astronaut }) => {
  return astronaut.otherImages && astronaut.otherImages.length > 0 ? (
    <div className="bcw">
      <div className="rel p15 container--xl ma">
        <div className="x f fr jcb fw jcs">
          {astronaut.otherImages.map((image) => (
            <div
              key={image.asset._id}
              className="f1 pr1 pb1 ma mt0 show--s"
              style={{ minWidth: '275px', maxWidth: '80%' }}
            >
              <ImageSanity image={image} />
            </div>
          ))}
          {astronaut.otherImages.map((image) => (
            <div
              key={image.asset._id}
              className="pr1 pb1 ma mt0 hide--s"
              style={{ width: '100%' }}
            >
              <ImageSanity image={image} />
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : null;
};

export default AstronautOtherImages;
