import React, { FC } from 'react';
import { Award } from 'superclient';
import AwardCell from './astronaut-award-cell';
import HorizontalList from '../core/horizontal-list';

const AstronautBadges: FC<{ awards: Award[] }> = ({ awards }) => {
  return (
    <HorizontalList title="Badges" className="cw">
      {awards.map((award, i) => (
        <AwardCell key={i} award={award} />
      ))}
    </HorizontalList>
  );
};

export default AstronautBadges;
