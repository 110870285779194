import React, { FC, useEffect, useMemo, useState } from 'react';
import { breakdownDuration, CountdownComponents } from 'superclient';

const runBreakdown = (
  duration: number,
  ongoingSince?: string
): CountdownComponents => {
  let total = duration;
  if (ongoingSince) {
    const nowTimestamp = Date.parse(new Date().toISOString());
    const dateTimestamp = Date.parse(ongoingSince || new Date().toISOString());
    total += nowTimestamp - dateTimestamp;
  }
  return breakdownDuration(total);
};

const MiniTicker: FC<{
  duration: number;
  ongoingSince?: string;
  showSeconds?: boolean;
  className?: string;
}> = ({ duration, ongoingSince, showSeconds, className }) => {
  console.log('MiniTicker', duration, ongoingSince, showSeconds, className);
  const [breakdown, setBreakdown] = useState<CountdownComponents>(
    runBreakdown(duration, ongoingSince)
  );

  useEffect(() => {
    const updateTime = () => {
      setBreakdown(runBreakdown(duration, ongoingSince));
    };
    const interval = setInterval(updateTime, 1000);
    updateTime();
    return () => {
      clearInterval(interval);
    };
  }, [duration, ongoingSince]);

  const { dayString, hourString, minuteString, secondString } = breakdown;

  return (
    <div className={className}>
      <span className="bau-bold h4 m0 ml0 p0 js-launch-timer">
        <span className="pr015">{dayString}</span>
        <span className="xsmall mr05">D</span>:
        <span className="pl025 pr015">{hourString}</span>
        <span className="xsmall mr05">H</span>:
        <span className="pl025 pr015">{minuteString}</span>
        <span className="xsmall mr05">M</span>
        {ongoingSince && showSeconds && (
          <>
            :<span className="pl025">{secondString}</span>
            <span className="xsmall mr1">S</span>
          </>
        )}
      </span>
    </div>
  );
};

export default MiniTicker;
