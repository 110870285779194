import React, { FC } from 'react';
import { Award } from 'superclient';
import ImageSanity from '../core/image-sanity';

const AwardCell: FC<{ award: Award }> = ({ award }) => {
  return (
    <div
      className="mr2 inline-block vat"
      style={{ width: '170px', verticalAlign: 'top' }}
    >
      <div
        className="rel"
        style={{
          width:
            20 * award.awardType.whiteImage.asset.metadata.dimensions.aspectRatio,
          height: 20
        }}
      >
        <ImageSanity
          className="abs x y top left"
          image={award.awardType.whiteImage}
          alt={award.title}
        />
      </div>
      <div className="pt1 pb0 h5 caps cw">{award.title}</div>
      {award.description && award.description.length > 0 && (
        <div className="pt05 pb2 bau small cw" style={{ width: '150px' }}>
          {award.description}
        </div>
      )}
    </div>
  );
};

export default AwardCell;
