import React, { FC } from 'react';
import { Mission } from 'superclient';
import HorizontalList from '../core/horizontal-list';
import { ListTypes } from '../core/super-card';
import MissionCard from '../missions/mission-card';

const AstronautMissionList: FC<{ missions: Mission[] }> = ({ missions }) => {
  return (
    <HorizontalList title="Missions">
      {missions.map((mission, i) => (
        <MissionCard
          key={i}
          mission={mission}
          listType={ListTypes.CardListX}
          white
        />
      ))}
    </HorizontalList>
  );
};

export default AstronautMissionList;
