import React, { FC, useMemo } from 'react';
import BlockContent from '@sanity/block-content-to-react';
import { Astronaut } from 'superclient';
import MetaModule from '../components/core/meta-module';
import AstronautDetailBar from '../components/astronauts/astronaut-detail-bar';
import AstronautDetailHeader from '../components/astronauts/astronaut-detail-header';
import MissionList from '../components/astronauts/astronaut-mission-list';
import AstronautCardList from '../components/astronauts/astronaut-card-list';
import AstronautBadges from '../components/astronauts/astronaut-badges';
import AstronautOtherImages from '../components/astronauts/astronaut-other-images';
import { Superclient } from '../clients/superclient';

const TemplateAstronaut: FC<{
  pageContext: {
    astronaut: Astronaut;
    crewmates: Astronaut[];
    filterFields: string[];
  };
}> = ({ pageContext: { astronaut: _astronaut, crewmates, filterFields } }) => {
  const { astronaut } = Superclient.useAstronaut(
    _astronaut.slug.current,
    _astronaut
  );

  const sortedMissions = useMemo(() => {
    return (astronaut?.missions || []).sort((a, b) => {
      return (
        new Date(b?.vagueLaunchDate).getTime() -
        new Date(a?.vagueLaunchDate).getTime()
      );
    });
  }, [astronaut?.missions]);

  return (
    <div className="template__astronauts">
      <MetaModule
        title={astronaut.name}
        path={`/astronauts/${astronaut.slug.current}`}
        sanityImage={astronaut.image}
      />
      <AstronautDetailBar filterFields={filterFields} />
      <AstronautDetailHeader astronaut={astronaut} />

      <div className="bcg pt1">
        {astronaut.missions.length > 0 && <MissionList missions={sortedMissions} />}

        <div className="bcb">
          {astronaut.awards && astronaut.awards.length > 0 && (
            <AstronautBadges awards={astronaut.awards} />
          )}

          {crewmates.length > 0 && (
            <AstronautCardList title={'Crewmates'} astronauts={crewmates} />
          )}
        </div>

        <AstronautOtherImages astronaut={astronaut} />

        {astronaut.description && (
          <div className="bcw">
            <div className="container--xl ma pt1">
              {astronaut.description && (
                <>
                  <div className="px1 pt2 pb0 h5 caps">Bio</div>
                  <div className="px1 pb1">
                    {<BlockContent blocks={astronaut.description} />}
                  </div>
                </>
              )}
            </div>
          </div>
        )}

        {astronaut.overview && (
          <div className="px1 py2 container--xl mxa">
            <div className=" pb1 h5 caps">WIKIPEDIA EXCERPT</div>
            <div className="h4">
              {astronaut.overview}
              {astronaut.overviewSourceUrl && astronaut.overviewSourceUrl !== '' && (
                <div className="pt05">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bau small caps under"
                    href={astronaut.overviewSourceUrl}
                  >
                    source
                  </a>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TemplateAstronaut;
